define('client/models/article-ml-summary', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ArticleMlSummary = _emberData.default.Model.extend({
        summary: _emberData.default.attr('string'),
        impactSummary: _emberData.default.attr('array'),
        topFiveArticles: _emberData.default.attr('array')

    });

    ArticleMlSummary.pathForType = "article_ml_summaries/summary_details";

    exports.default = ArticleMlSummary;
});